<template>
  <div class="container oci-check-in-out-body">    
    <DCNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Notes</b>
        </b-row>
        <b-row class="pb-1">
          <b-col cols="12">
            <b-form-group
            label="Notes"
            label-for="waste-notes"
            >                                        
              <b-form-input
              v-model="currentBooking.reason_notes"
              placeholder=""
              name="name-edit"
              :disabled="isInputDisabled"
              />                      
            </b-form-group> 
          </b-col>  
        </b-row>
        <b-row class="pb-0">
            <b-col class="driver-upload-button">
            <input
              type="file"
              id="cameraCapture"
              style="display: none;"
              accept="image/*"
              capture="environment"
              @change="handleFileCapture"
            />            
            <b-button
                type="submit"
                @click="triggerCamera"
                class="w-100"
                :disabled="isInputDisabled"
            >
                Upload &nbsp;
                <feather-icon
                  icon="CameraIcon"
                  size="12"
                />
            </b-button>
            </b-col>
        </b-row>
        <b-row class="pb-0">
            <b-col class="driver-upload-button">
            <input
              type="file"
              id="photoUpload"
              style="display: none;"
              accept="image/*"
              @change="handleFileUpload"
            />            
            <b-button
                type="submit"
                @click="triggerFileInput"
                class="w-100"
                :disabled="isInputDisabled"
            >
                Upload photo
            </b-button>
            </b-col>
        </b-row>   

        <b-row class="pb-1">
          <b-col>
            <div
              v-for="(item, index) in currentBooking.supporting_documents"
              :key="index"
              class="mb-2"
            >
              <b-row class="align-items-center">
                <b-col cols="8">
                  <span>{{ item.name }}</span>
                </b-col>
                <b-col cols="2" class="text-right">
                    <feather-icon 
                    id="type" 
                    icon="DownloadIcon" 
                    size="14" 
                    style="stroke-width: 3;"
                    @click="downloadDocument(item)"
                    />
                </b-col>
                <b-col cols="2" class="text-right">
                    <feather-icon 
                    id="type" 
                    icon="TrashIcon" 
                    size="14" 
                    style="stroke-width: 3;"
                    @click="deleteDocument(item)"
                    />
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>        

        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import DCNumberView from '@/views/DCNumberView.vue'
import { generalMethods } from '@/components/General-Functions'
import swal from 'sweetalert2'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,    BButton,
    DCNumberView,
  },
  data() {
    return {
      reason: '',     
    };
  },  
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    ...mapGetters({ bookingLines: 'app/getBookingLines' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }    
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      this.$router.push('/client_signature_dc')
    },
    triggerFileInput() {
      // Trigger the hidden file input
      document.getElementById('photoUpload').click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (this.file) {
        console.log('Selected file:', this.file);
        // You can now upload the file or perform further processing
        this.readFileContent(this.file)
      }
    },
    readFileContent(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileContent = event.target.result; // Base64 encoded image
        // console.log('Image content:', this.fileContent);
        // send image as attachment
        bookingsMethods.saveDCNotes(this.currentBooking.id, this.currentBooking.reason_notes)
        bookingsMethods.CreateDCAttachments(this.currentBooking.id, this.fileContent, this.file.name)
      };
      reader.readAsDataURL(file); // Reads the file as Base64
    },
    triggerCamera() {
      document.getElementById('cameraCapture').click();
    },
    handleFileCapture(event) {
      const capturedFile = event.target.files[0];
      if (capturedFile) {
        this.file = capturedFile;
        this.readFileContent(capturedFile);
      }
    },
    downloadDocument(item) {
      console.log('item', item)
      if (!item || !item.data || !item.name) {
        alert('Document data or name is missing.');
        return;
      }

      try {
        // Convert Base64 data to a Blob (if the data is Base64 encoded)
        const byteCharacters = atob(item.data.base64); // Decode Base64
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byte array
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); // Use a MIME type based on your file type
        const url = URL.createObjectURL(blob);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = item.name; // The file name for the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the document:', error);
        alert('An error occurred while downloading the document.');
      }
    },
    deleteDocument(item) {
      if (!item || !item.data || !item.name) {
        // alert('Document data or name is missing.');
        return;
      }

      swal.fire({
        title: '',
        html: 'Are you sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'I\'m sure',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmIcon: 'primary',
          confirmButton: 'btn btn-primary order-2 ml-1',
          cancelIcon: 'outline-primary',
          cancelButton: 'btn btn-outline-primary order-1 mr-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed === true) {
          bookingsMethods.deleteDCAttachments(this.currentBooking.id, item.id)
            .then(() => {
              // Remove the item from bookingDockets.supporting_documents
              this.currentBooking.supporting_documents = this.currentBooking.supporting_documents.filter(
                doc => doc.id !== item.id
              );
              generalMethods.messageBox('', 'File deleted.', 'success');
              this.$forceUpdate();
            })
            .catch((error) => {
              console.error('Error deleting the document:', error);
              generalMethods.messageBox('', 'Failed to delete file.', 'error');
            });        
        }
      })
    },    
  },  
}
</script>
